import Vue from "vue";
import VueRouter from "vue-router";


import Login from "../views/auth/Login.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import Logout from "@/views/auth/Logout";

import Home from "@/views/layout/Home";
import SkupBase from "../views/pages/Skupovi/SkupBase";
import Skupovi from "../views/pages/Skupovi/Skupovi";
import SkupCreate from "../views/pages/Skupovi/SkupCreate";
import SkupDetails from "../views/pages/Skupovi/SkupDetails";
import SkupEdit from "../views/pages/Skupovi/SkupEdit";
import SkupPrijavaBase from "../views/pages/Skupovi/SkupPrijave/SkupPrijavaBase";
import SkupPrijave from "../views/pages/Skupovi/SkupPrijave/SkupPrijave";
import SkupPrijavaCreate from "../views/pages/Skupovi/SkupPrijave/SkupPrijavaCreate";
import SkupPrijavaDetails from "../views/pages/Skupovi/SkupPrijave/SkupPrijavaDetails";
import SkupPrijavaEdit from "../views/pages/Skupovi/SkupPrijave/SkupPrijavaEdit";
import SkupPrometBase from "../views/pages/Skupovi/SkupPromet/SkupPrometBase";
import SkupPromet from "../views/pages/Skupovi/SkupPromet/SkupPromet";
import SkupPrometCreate from "../views/pages/Skupovi/SkupPromet/SkupPrometCreate";
import SkupPrometDetails from "../views/pages/Skupovi/SkupPromet/SkupPrometDetails";
import SkupPrometEdit from "../views/pages/Skupovi/SkupPromet/SkupPrometEdit";
import SkupStavkaBase from "../views/pages/Skupovi/SkupStavke/SkupStavkaBase";
import SkupStavke from "../views/pages/Skupovi/SkupStavke/SkupStavke";
import SkupStavkaCreate from "../views/pages/Skupovi/SkupStavke/SkupStavkaCreate";
import SkupStavkaDetails from "../views/pages/Skupovi/SkupStavke/SkupStavkaDetails";
import SkupStavkaEdit from "../views/pages/Skupovi/SkupStavke/SkupStavkaEdit";
import SkupStavkaImportExcel from "../views/pages/Skupovi/SkupStavke/SkupStavkaImportExcel";
import SkupStavkeNotImportedExcel from "../views/pages/Skupovi/SkupStavke/SkupStavkeNotImportedExcel";
import SkupSuorganizatorBase from "../views/pages/Skupovi/SkupSuorganizatori/SkupSuorganizatorBase";
import SkupSuorganizatori from "../views/pages/Skupovi/SkupSuorganizatori/SkupSuorganizatori";
import SkupSuorganizatorCreate from "../views/pages/Skupovi/SkupSuorganizatori/SkupSuorganizatorCreate";
import SkupSuorganizatorDetails from "../views/pages/Skupovi/SkupSuorganizatori/SkupSuorganizatorDetails";
import SkupSuorganizatorEdit from "../views/pages/Skupovi/SkupSuorganizatori/SkupSuorganizatorEdit";
import SkupTemaBase from "../views/pages/Skupovi/SkupTeme/SkupTemaBase";
import SkupTeme from "../views/pages/Skupovi/SkupTeme/SkupTeme";
import SkupTemaCreate from "../views/pages/Skupovi/SkupTeme/SkupTemaCreate";
import SkupTemaDetails from "../views/pages/Skupovi/SkupTeme/SkupTemaDetails";
import SkupTemaEdit from "../views/pages/Skupovi/SkupTeme/SkupTemaEdit";
import SkupTema2Base from "../views/pages/Skupovi/SkupTeme2/SkupTema2Base";
import SkupTeme2 from "../views/pages/Skupovi/SkupTeme2/SkupTeme2";
import SkupTema2Create from "../views/pages/Skupovi/SkupTeme2/SkupTema2Create";
import SkupTema2Details from "../views/pages/Skupovi/SkupTeme2/SkupTema2Details";
import SkupTema2Edit from "../views/pages/Skupovi/SkupTeme2/SkupTema2Edit";
import Emailing from "../views/pages/Skupovi/SkupEmailing/Emailing";
import OsobaBase from "../views/pages/Osobe/OsobaBase";
import Osobe from "../views/pages/Osobe/Osobe";
import OsobaCreate from "../views/pages/Osobe/OsobaCreate";
import OsobaEdit from "../views/pages/Osobe/OsobaEdit";
import OsobaDetails from "../views/pages/Osobe/OsobaDetails";
import OsobaDodatnaAktivnostBase from "../views/pages/Osobe/OsobaDodatneAktivnosti/OsobaDodatnaAktivnostBase";
import OsobaDodatneAktivnosti from "../views/pages/Osobe/OsobaDodatneAktivnosti/OsobaDodatneAktivnosti";
import OsobaDodatnaAktivnostCreate from "../views/pages/Osobe/OsobaDodatneAktivnosti/OsobaDodatnaAktivnostCreate";
import OsobaDodatnaAktivnostDetails from "../views/pages/Osobe/OsobaDodatneAktivnosti/OsobaDodatnaAktivnostDetails";
import OsobaDodatnaAktivnostEdit from "../views/pages/Osobe/OsobaDodatneAktivnosti/OsobaDodatnaAktivnostEdit";
import OsobaGrupaStatusaBase from "../views/pages/Osobe/OsobaGrupeStatusa/OsobaGrupaStatusaBase";
import OsobaGrupeStatusa from "../views/pages/Osobe/OsobaGrupeStatusa/OsobaGrupeStatusa";
import OsobaGrupaStatusaCreate from "../views/pages/Osobe/OsobaGrupeStatusa/OsobaGrupaStatusaCreate";
import OsobaGrupaStatusaDetails from "../views/pages/Osobe/OsobaGrupeStatusa/OsobaGrupaStatusaDetails";
import OsobaGrupaStatusaEdit from "../views/pages/Osobe/OsobaGrupeStatusa/OsobaGrupaStatusaEdit";
import OsobaPotvrda from "../views/pages/Osobe/OsobaPotvrde/OsobaPotvrda";
import OsobaRazdobljeBase from "../views/pages/Osobe/OsobaRazdoblja/OsobaRazdobljeBase";
import OsobaRazdoblja from "../views/pages/Osobe/OsobaRazdoblja/OsobaRazdoblja";
import OsobaRazdobljeCreate from "../views/pages/Osobe/OsobaRazdoblja/OsobaRazdobljeCreate";
import OsobaRazdobljeDetails from "../views/pages/Osobe/OsobaRazdoblja/OsobaRazdobljeDetails";
import OsobaRazdobljeEdit from "../views/pages/Osobe/OsobaRazdoblja/OsobaRazdobljeEdit";
import OsobaStavkaBase from "../views/pages/Osobe/OsobaStavke/OsobaStavkaBase";
import OsobaStavke from "../views/pages/Osobe/OsobaStavke/OsobaStavke";
import OsobaTvrtkaBase from "../views/pages/Osobe/OsobaTvrtke/OsobaTvrtkaBase";
import OsobaTvrtke from "../views/pages/Osobe/OsobaTvrtke/OsobaTvrtke";
import OsobaTvrtkaCreate from "../views/pages/Osobe/OsobaTvrtke/OsobaTvrtkaCreate";
import OsobaTvrtkaDetails from "../views/pages/Osobe/OsobaTvrtke/OsobaTvrtkaDetails";
import OsobaTvrtkaEdit from "../views/pages/Osobe/OsobaTvrtke/OsobaTvrtkaEdit";
import OsobaUmanjeniSatBase from "../views/pages/Osobe/OsobaUmanjeniSati/OsobaUmanjeniSatBase";
import OsobaUmanjeniSati from "../views/pages/Osobe/OsobaUmanjeniSati/OsobaUmanjeniSati";
import OsobaUmanjeniSatCreate from "../views/pages/Osobe/OsobaUmanjeniSati/OsobaUmanjeniSatCreate";
import OsobaUmanjeniSatDetails from "../views/pages/Osobe/OsobaUmanjeniSati/OsobaUmanjeniSatDetails";
import OsobaUmanjeniSatEdit from "../views/pages/Osobe/OsobaUmanjeniSati/OsobaUmanjeniSatEdit";
import DodatnaAktivnostBase from "../views/pages/Postavke/DodatneAktivnosti/DodatnaAktivnostBase";
import DodatneAktivnosti from "../views/pages/Postavke/DodatneAktivnosti/DodatneAktivnosti";
import DodatnaAktivnostCreate from "../views/pages/Postavke/DodatneAktivnosti/DodatnaAktivnostCreate";
import DodatnaAktivnostDetails from "../views/pages/Postavke/DodatneAktivnosti/DodatnaAktivnostDetails";
import DodatnaAktivnostEdit from "../views/pages/Postavke/DodatneAktivnosti/DodatnaAktivnostEdit";
import DrzavaBase from "../views/pages/Postavke/Drzave/DrzavaBase";
import Drzave from "../views/pages/Postavke/Drzave/Drzave";
import DrzavaCreate from "../views/pages/Postavke/Drzave/DrzavaCreate";
import DrzavaEdit from "../views/pages/Postavke/Drzave/DrzavaEdit";
import GrupaStatusaBase from "../views/pages/Postavke/GrupeStatusa/GrupaStatusaBase";
import GrupeStatusa from "../views/pages/Postavke/GrupeStatusa/GrupeStatusa";
import GrupaStatusaCreate from "../views/pages/Postavke/GrupeStatusa/GrupaStatusaCreate";
import GrupaStatusaDetails from "../views/pages/Postavke/GrupeStatusa/GrupaStatusaDetails";
import GrupaStatusaEdit from "../views/pages/Postavke/GrupeStatusa/GrupaStatusaEdit";
import PodrucjeTemeBase from "../views/pages/Postavke/PodrucjaTema/PodrucjeTemeBase";
import PodrucjaTema from "../views/pages/Postavke/PodrucjaTema/PodrucjaTema";
import PodrucjeTemeCreate from "../views/pages/Postavke/PodrucjaTema/PodrucjeTemeCreate";
import PodrucjeTemeDetails from "../views/pages/Postavke/PodrucjaTema/PodrucjeTemeDetails";
import PodrucjeTemeEdit from "../views/pages/Postavke/PodrucjaTema/PodrucjeTemeEdit";
import PorezBase from "../views/pages/Postavke/Porezi/PorezBase";
import Porezi from "../views/pages/Postavke/Porezi/Porezi";
import PorezCreate from "../views/pages/Postavke/Porezi/PorezCreate";
import PorezDetails from "../views/pages/Postavke/Porezi/PorezDetails";
import PorezEdit from "../views/pages/Postavke/Porezi/PorezEdit";
import PostaBase from "../views/pages/Postavke/Poste/PostaBase";
import Poste from "../views/pages/Postavke/Poste/Poste";
import PostaCreate from "../views/pages/Postavke/Poste/PostaCreate";
import PostaDetails from "../views/pages/Postavke/Poste/PostaDetails";
import PostaEdit from "../views/pages/Postavke/Poste/PostaEdit";
import SuorganizatorBase from "../views/pages/Postavke/Suorganizatori/SuorganizatorBase";
import Suorganizatori from "../views/pages/Postavke/Suorganizatori/Suorganizatori";
import SuorganizatorCreate from "../views/pages/Postavke/Suorganizatori/SuorganizatorCreate";
import SuorganizatorDetails from "../views/pages/Postavke/Suorganizatori/SuorganizatorDetails";
import SuorganizatorEdit from "../views/pages/Postavke/Suorganizatori/SuorganizatorEdit";
import UmanjeniSatBase from "../views/pages/Postavke/UmanjeniSati/UmanjeniSatBase";
import UmanjeniSati from "../views/pages/Postavke/UmanjeniSati/UmanjeniSati";
import UmanjeniSatCreate from "../views/pages/Postavke/UmanjeniSati/UmanjeniSatCreate";
import UmanjeniSatDetails from "../views/pages/Postavke/UmanjeniSati/UmanjeniSatDetails";
import UmanjeniSatEdit from "../views/pages/Postavke/UmanjeniSati/UmanjeniSatEdit";
import VrstaPrometaBase from "../views/pages/Postavke/VrstePrometa/VrstaPrometaBase";
import VrstePrometa from "../views/pages/Postavke/VrstePrometa/VrstePrometa";
import VrstaPrometaCreate from "../views/pages/Postavke/VrstePrometa/VrstaPrometaCreate";
import VrstaPrometaDetails from "../views/pages/Postavke/VrstePrometa/VrstaPrometaDetails";
import VrstaPrometaEdit from "../views/pages/Postavke/VrstePrometa/VrstaPrometaEdit";
import VrstaSkupaBase from "../views/pages/Postavke/VrsteSkupova/VrstaSkupaBase";
import VrsteSkupova from "../views/pages/Postavke/VrsteSkupova/VrsteSkupova";
import VrstaSkupaCreate from "../views/pages/Postavke/VrsteSkupova/VrstaSkupaCreate";
import VrstaSkupaDetails from "../views/pages/Postavke/VrsteSkupova/VrstaSkupaDetails";
import VrstaskupaEdit from "../views/pages/Postavke/VrsteSkupova/VrstaSkupaEdit";
import VrstaSudionikaBase from "../views/pages/Postavke/VrsteSudionika/VrstaSudionikaBase";
import VrsteSudionika from "../views/pages/Postavke/VrsteSudionika/VrsteSudionika";
import VrstaSudionikaCreate from "../views/pages/Postavke/VrsteSudionika/VrstaSudionikaCreate";
import VrstaSudionikaDetails from "../views/pages/Postavke/VrsteSudionika/VrstaSudionikaDetails";
import VrstaSudionikaEdit from "../views/pages/Postavke/VrsteSudionika/VrstaSudionikaEdit";
import VrstaSuorganizatoraBase from "../views/pages/Postavke/VrsteSuorganizatora/VrstaSuorganizatoraBase";
import VrsteSuorganizatora from "../views/pages/Postavke/VrsteSuorganizatora/VrsteSuorganizatora";
import VrstaSuorganizatoraCreate from "../views/pages/Postavke/VrsteSuorganizatora/VrstaSuorganizatoraCreate";
import VrstaSuorganizatoraDetails from "../views/pages/Postavke/VrsteSuorganizatora/VrstaSuorganizatoraDetails";
import VrstaSuorganizatoraEdit from "../views/pages/Postavke/VrsteSuorganizatora/VrstaSuorganizatoraEdit";
import VrstaTemeBase from "../views/pages/Postavke/VrsteTema/VrstaTemeBase";
import VrsteTema from "../views/pages/Postavke/VrsteTema/VrsteTema";
import VrstaTemeCreate from "../views/pages/Postavke/VrsteTema/VrstaTemeCreate";
import VrstaTemeDetails from "../views/pages/Postavke/VrsteTema/VrstaTemeDetails";
import VrstaTemeEdit from "../views/pages/Postavke/VrsteTema/VrstaTemeEdit";
import VrstaTeme2Base from "../views/pages/Postavke/VrsteTema2/VrstaTeme2Base";
import VrsteTema2 from "../views/pages/Postavke/VrsteTema2/VrsteTema2";
import VrstaTeme2Create from "../views/pages/Postavke/VrsteTema2/VrstaTeme2Create";
import VrstaTeme2Edit from "../views/pages/Postavke/VrsteTema2/VrstaTeme2Edit";
import VrstaTeme2Details from "../views/pages/Postavke/VrsteTema2/VrstaTeme2Details";
import Zupanije from "../views/pages/Postavke/Zupanije/Zupanije";
import ZupanijaBase from "../views/pages/Postavke/Zupanije/ZupanijaBase";
import ZupanijaCreate from "../views/pages/Postavke/Zupanije/ZupanijaCreate";
import ZupanijaDetails from "../views/pages/Postavke/Zupanije/ZupanijaDetails";
import ZupanijaEdit from "../views/pages/Postavke/Zupanije/ZupanijaEdit";
import AdministratorBase from "../views/pages/Administratori/AdministratorBase";
import Administratori from "../views/pages/Administratori/Administratori";
import AdministratorCreate from "../views/pages/Administratori/AdministratorCreate";
import AdministratorDetails from "../views/pages/Administratori/AdministratorDetails";
import AdministratorEdit from "../views/pages/Administratori/AdministratorEdit";
import DrzavaDetails from "../views/pages/Postavke/Drzave/DrzavaDetails";
import IzvjestajiHKIE from "@/views/pages/Izvjestaji/IzvjestajiHKIE";
import IzvjestajiHKIS from "@/views/pages/Izvjestaji/IzvjestajiHKIS";
import IzvjestajiHKIG from "@/views/pages/Izvjestaji/IzvjestajiHKIG";

Vue.use(VueRouter);

const guest = (to, from, next) => {
  if (!localStorage.getItem("access_token")) {
    return next();
  } else {
    return next("/");
  }
};
const auth = (to, from, next) => {
  if (localStorage.getItem("access_token")) {
    return next();
  } else {
    return next("/login");
  }
};

const routes = [
  {
    path: "/login",
    name: "Login",
    beforeEnter: guest,
    component: Login,
  },
  {
    path: "/forgot-password",
    beforeEnter: guest,
    component: ForgotPassword,
  },
  {
    path: "/reset-password/:token",
    beforeEnter: guest,
    component: ResetPassword,
  },
  {
    path: "/logout",
    beforeEnter: auth,
    component: Logout,
  },
  {
    path: "/",
    beforeEnter: auth,
    component: Home,
    children: [
      {
        path: "/",
        beforeEnter: auth,
        component: SkupBase,
        children: [
          {
            path: "/",
            beforeEnter: auth,
            component: Skupovi
          }
        ]
      },
      {
        path: "/skupovi",
        beforeEnter: auth,
        component: SkupBase,
        children: [
          {
            path: "",
            beforeEnter: auth,
            component: Skupovi,
          },
          {
            path: "create",
            beforeEnter: auth,
            component: SkupCreate,
          },
          {
            path: ":skup_id",
            beforeEnter: auth,
            component: SkupDetails,
          },
          {
            path: ":skup_id/edit",
            beforeEnter: auth,
            component: SkupEdit,
          },
          {
            path: ":skup_id/teme",
            beforeEnter: auth,
            component: SkupTemaBase,
            children: [
              {
                path: "/",
                beforeEnter: auth,
                component: SkupTeme,
              },
              {
                path: "create",
                beforeEnter: auth,
                component: SkupTemaCreate,
              },
              {
                path: ":tema_id",
                beforeEnter: auth,
                component: SkupTemaDetails,
              },
              {
                path: ":tema_id/edit",
                beforeEnter: auth,
                component: SkupTemaEdit,
              }
            ]
          },
          {
            path: ":skup_id/teme2",
            beforeEnter: auth,
            component: SkupTema2Base,
            children: [
              {
                path: "",
                beforeEnter: auth,
                component: SkupTeme2,
              },
              {
                path: "create",
                beforeEnter: auth,
                component: SkupTema2Create,
              },
              {
                path: ":tema2_id",
                beforeEnter: auth,
                component: SkupTema2Details,
              },
              {
                path: ":tema2_id/edit",
                beforeEnter: auth,
                component: SkupTema2Edit,
              }
            ]
          },
          {
            path: ":skup_id/suorganizatori",
            beforeEnter: auth,
            component: SkupSuorganizatorBase,
            children: [
              {
                path: "",
                beforeEnter: auth,
                component: SkupSuorganizatori,
              },
              {
                path: "create",
                beforeEnter: auth,
                component: SkupSuorganizatorCreate,
              },
              {
                path: ":skup_suorganizator_id",
                beforeEnter: auth,
                component: SkupSuorganizatorDetails,
              },
              {
                path: ":skup_suorganizator_id/edit",
                beforeEnter: auth,
                component: SkupSuorganizatorEdit,
              }
            ]
          },
          {
            path: ":skup_id/prijave",
            beforeEnter: auth,
            component: SkupPrijavaBase,
            children: [
              {
                path: "",
                beforeEnter: auth,
                component: SkupPrijave,
              },
              {
                path: "create",
                beforeEnter: auth,
                component: SkupPrijavaCreate,
              },
              {
                path: ":skup_osoba_id",
                beforeEnter: auth,
                component: SkupPrijavaDetails,
              },
              {
                path: ":skup_osoba_id/edit",
                beforeEnter: auth,
                component: SkupPrijavaEdit,
              }
            ]
          },
          {
            path: ":skup_id/promet",
            beforeEnter: auth,
            component: SkupPrometBase,
            children: [
              {
                path: "",
                beforeEnter: auth,
                component: SkupPromet,
              },
              {
                path: "create",
                beforeEnter: auth,
                component: SkupPrometCreate,
              },
              {
                path: ":promet_id",
                beforeEnter: auth,
                component: SkupPrometDetails,
              },
              {
                path: ":promet_id/edit",
                beforeEnter: auth,
                component: SkupPrometEdit,
              }
            ]
          },
          {
            path: ":skup_id/stavke",
            beforeEnter: auth,
            component: SkupStavkaBase,
            children: [
              {
                path: "",
                beforeEnter: auth,
                component: SkupStavke,
              },
              {
                path: "create",
                beforeEnter: auth,
                component: SkupStavkaCreate,
              },
              {
                path: "import-excel",
                beforeEnter: auth,
                component: SkupStavkaImportExcel,
              },
              {
                path: "not-imported-excel",
                beforeEnter: auth,
                component: SkupStavkeNotImportedExcel,
              },
              {
                path: ":stavka_id",
                beforeEnter: auth,
                component: SkupStavkaDetails,
              },
              {
                path: ":stavka_id/edit",
                beforeEnter: auth,
                component: SkupStavkaEdit,
              }
            ]
          },
          {
            path: ":skup_id/emailing",
            beforeEnter: auth,
            component: Emailing,
          },
        ]
      },
      {
        path: '/izvjestaji-hkie',
        beforeEnter: auth,
        component: IzvjestajiHKIE,
      },
      {
        path: '/izvjestaji-hkis',
        beforeEnter: auth,
        component: IzvjestajiHKIS,
      },
      {
        path: '/izvjestaji-hkig',
        beforeEnter: auth,
        component: IzvjestajiHKIG,
      },
      {
        path: '/osobe',
        beforeEnter: auth,
        component: OsobaBase,
        children:[
          {
            path: "",
            beforeEnter: auth,
            component: Osobe,
          },
          {
            path: "create",
            beforeEnter: auth,
            component: OsobaCreate,
          },
          {
            path: ":osoba_id",
            beforeEnter: auth,
            component: OsobaDetails,
          },
          {
            path: ':osoba_id/edit',
            beforeEnter: auth,
            component: OsobaEdit
          },
          {
            path: ":osoba_id/dodatne-aktivnosti",
            beforeEnter: auth,
            component: OsobaDodatnaAktivnostBase,
            children: [
              {
                path: "",
                beforeEnter: auth,
                component: OsobaDodatneAktivnosti,
              },
              {
                path: "create",
                beforeEnter: auth,
                component: OsobaDodatnaAktivnostCreate,
              },
              {
                path: ":osoba_dodatna_aktivnost_id",
                beforeEnter: auth,
                component: OsobaDodatnaAktivnostDetails,
              },
              {
                path: ":osoba_dodatna_aktivnost_id/edit",
                beforeEnter: auth,
                component: OsobaDodatnaAktivnostEdit,
              }
            ]
          },
          {
            path: ":osoba_id/grupe-statusa",
            beforeEnter: auth,
            component: OsobaGrupaStatusaBase,
            children: [
              {
                path: "",
                beforeEnter: auth,
                component: OsobaGrupeStatusa,
              },
              {
                path: "create",
                beforeEnter: auth,
                component: OsobaGrupaStatusaCreate,
              },
              {
                path: ":osoba_grupa_statusa_id",
                beforeEnter: auth,
                component: OsobaGrupaStatusaDetails,
              },
              {
                path: ":osoba_grupa_statusa_id/edit",
                beforeEnter: auth,
                component: OsobaGrupaStatusaEdit,
              }
            ]
          },
          {
            path: ":osoba_id/potvrde",
            beforeEnter: auth,
            component: OsobaPotvrda,
          },
          {
            path: ":osoba_id/razdoblja",
            beforeEnter: auth,
            component: OsobaRazdobljeBase,
            children: [
              {
                path: "",
                beforeEnter: auth,
                component: OsobaRazdoblja,
              },
              {
                path: "create",
                beforeEnter: auth,
                component: OsobaRazdobljeCreate,
              },
              {
                path: ":osoba_razdoblje_id",
                beforeEnter: auth,
                component: OsobaRazdobljeDetails,
              },
              {
                path: ":osoba_razdoblje_id/edit",
                beforeEnter: auth,
                component: OsobaRazdobljeEdit,
              }
            ]
          },
          {
            path: ":osoba_id/stavke",
            beforeEnter: auth,
            component: OsobaStavkaBase,
            children: [
              {
                path: "",
                beforeEnter: auth,
                component: OsobaStavke,
              }
            ]
          },
          {
            path: ":osoba_id/tvrtke",
            beforeEnter: auth,
            component: OsobaTvrtkaBase,
            children: [
              {
                path: "",
                beforeEnter: auth,
                component: OsobaTvrtke,
              },
              {
                path: "create",
                beforeEnter: auth,
                component: OsobaTvrtkaCreate,
              },
              {
                path: ":osoba_tvrtka_id",
                beforeEnter: auth,
                component: OsobaTvrtkaDetails,
              },
              {
                path: ":osoba_tvrtka_id/edit",
                beforeEnter: auth,
                component: OsobaTvrtkaEdit,
              }
            ]
          },
          {
            path: ":osoba_id/umanjeni-sati",
            beforeEnter: auth,
            component: OsobaUmanjeniSatBase,
            children: [
              {
                path: "",
                beforeEnter: auth,
                component: OsobaUmanjeniSati,
              },
              {
                path: "create",
                beforeEnter: auth,
                component: OsobaUmanjeniSatCreate,
              },
              {
                path: ":osoba_umanjeni_sati_id",
                beforeEnter: auth,
                component: OsobaUmanjeniSatDetails,
              },
              {
                path: ":osoba_umanjeni_sati_id/edit",
                beforeEnter: auth,
                component: OsobaUmanjeniSatEdit,
              }
            ]
          },
        ]
      },
      {
        path: "/administratori",
        component: AdministratorBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Administratori,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: AdministratorCreate,
          },
          {
            path: ':admin_id',
            beforeEnter: auth,
            component: AdministratorDetails,
          },
          {
            path: ':admin_id/edit',
            beforeEnter: auth,
            component: AdministratorEdit,
          },
        ]
      },
      {
        path: "/postavke/dodatne-aktivnosti",
        component: DodatnaAktivnostBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: DodatneAktivnosti,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: DodatnaAktivnostCreate,
          },
          {
            path: ':dodatna_aktivnost_id',
            beforeEnter: auth,
            component: DodatnaAktivnostDetails,
          },
          {
            path: ':dodatna_aktivnost_id/edit',
            beforeEnter: auth,
            component: DodatnaAktivnostEdit,
          },
        ]
      },
      {
        path: "/postavke/drzave",
        component: DrzavaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Drzave,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: DrzavaCreate,
          },
          {
            path: ':drzava_id',
            beforeEnter: auth,
            component: DrzavaDetails,
          },
          {
            path: ':drzava_id/edit',
            beforeEnter: auth,
            component: DrzavaEdit,
          },
        ]
      },
      {
        path: "/postavke/grupe-statusa",
        component: GrupaStatusaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: GrupeStatusa,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: GrupaStatusaCreate,
          },
          {
            path: ':grupa_statusa_id',
            beforeEnter: auth,
            component: GrupaStatusaDetails,
          },
          {
            path: ':grupa_statusa_id/edit',
            beforeEnter: auth,
            component: GrupaStatusaEdit,
          },
        ]
      },
      {
        path: "/postavke/podrucja-tema",
        component: PodrucjeTemeBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: PodrucjaTema,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: PodrucjeTemeCreate,
          },
          {
            path: ':podrucje_teme_id',
            beforeEnter: auth,
            component: PodrucjeTemeDetails,
          },
          {
            path: ':podrucje_teme_id/edit',
            beforeEnter: auth,
            component: PodrucjeTemeEdit,
          },
        ]
      },
      {
        path: "/postavke/porezi",
        component: PorezBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Porezi,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: PorezCreate,
          },
          {
            path: ':porez_id',
            beforeEnter: auth,
            component: PorezDetails,
          },
          {
            path: ':porez_id/edit',
            beforeEnter: auth,
            component: PorezEdit,
          },
        ]
      },
      {
        path: "/postavke/poste",
        component: PostaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Poste,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: PostaCreate,
          },
          {
            path: ':posta_id',
            beforeEnter: auth,
            component: PostaDetails,
          },
          {
            path: ':posta_id/edit',
            beforeEnter: auth,
            component: PostaEdit,
          },
        ]
      },
      {
        path: "/postavke/suorganizatori",
        component: SuorganizatorBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Suorganizatori,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: SuorganizatorCreate,
          },
          {
            path: ':suorganizator_id',
            beforeEnter: auth,
            component: SuorganizatorDetails,
          },
          {
            path: ':suorganizator_id/edit',
            beforeEnter: auth,
            component: SuorganizatorEdit,
          },
        ]
      },
      {
        path: "/postavke/umanjeni-sati",
        component: UmanjeniSatBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: UmanjeniSati,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: UmanjeniSatCreate,
          },
          {
            path: ':umanjeni_sat_id',
            beforeEnter: auth,
            component: UmanjeniSatDetails,
          },
          {
            path: ':umanjeni_sat_id/edit',
            beforeEnter: auth,
            component: UmanjeniSatEdit,
          },
        ]
      },
      {
        path: "/postavke/vrste-prometa",
        component: VrstaPrometaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: VrstePrometa,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: VrstaPrometaCreate,
          },
          {
            path: ':vrsta_prometa_id',
            beforeEnter: auth,
            component: VrstaPrometaDetails,
          },
          {
            path: ':vrsta_prometa_id/edit',
            beforeEnter: auth,
            component: VrstaPrometaEdit,
          },
        ]
      },
      {
        path: "/postavke/vrste-skupova",
        component: VrstaSkupaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: VrsteSkupova,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: VrstaSkupaCreate,
          },
          {
            path: ':vrsta_skupa_id',
            beforeEnter: auth,
            component: VrstaSkupaDetails,
          },
          {
            path: ':vrsta_skupa_id/edit',
            beforeEnter: auth,
            component: VrstaskupaEdit,
          },
        ]
      },
      {
        path: "/postavke/vrste-sudionika",
        component: VrstaSudionikaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: VrsteSudionika,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: VrstaSudionikaCreate,
          },
          {
            path: ':vrsta_sudionika_id',
            beforeEnter: auth,
            component: VrstaSudionikaDetails,
          },
          {
            path: ':vrsta_sudionika_id/edit',
            beforeEnter: auth,
            component: VrstaSudionikaEdit,
          },
        ]
      },
      {
        path: "/postavke/vrste-suorganizatora",
        component: VrstaSuorganizatoraBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: VrsteSuorganizatora,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: VrstaSuorganizatoraCreate,
          },
          {
            path: ':vrsta_suorganizatora_id',
            beforeEnter: auth,
            component: VrstaSuorganizatoraDetails,
          },
          {
            path: ':vrsta_suorganizatora_id/edit',
            beforeEnter: auth,
            component: VrstaSuorganizatoraEdit,
          },
        ]
      },
      {
        path: "/postavke/vrste-tema",
        component: VrstaTemeBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: VrsteTema,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: VrstaTemeCreate,
          },
          {
            path: ':vrsta_teme_id',
            beforeEnter: auth,
            component: VrstaTemeDetails,
          },
          {
            path: ':vrsta_teme_id/edit',
            beforeEnter: auth,
            component: VrstaTemeEdit,
          },
        ]
      },
      {
        path: "/postavke/vrste-tema2",
        component: VrstaTeme2Base,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: VrsteTema2,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: VrstaTeme2Create,
          },
          {
            path: ':vrsta_teme2_id',
            beforeEnter: auth,
            component: VrstaTeme2Details,
          },
          {
            path: ':vrsta_teme2_id/edit',
            beforeEnter: auth,
            component: VrstaTeme2Edit,
          },
        ]
      },
      {
        path: "/postavke/zupanije",
        component: ZupanijaBase,
        children: [
          {
            path: '',
            beforeEnter: auth,
            component: Zupanije,
          },
          {
            path: 'create',
            beforeEnter: auth,
            component: ZupanijaCreate,
          },
          {
            path: ':zupanija_id',
            beforeEnter: auth,
            component: ZupanijaDetails,
          },
          {
            path: ':zupanija_id/edit',
            beforeEnter: auth,
            component: ZupanijaEdit,
          },
        ]
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: '/',
  routes
});

export default router;
