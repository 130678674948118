<template>
    <div>
        <breadcrumbs
            :title="'Skupovi'"
            :breadcrumbs="breadcrumbs"
        ></breadcrumbs>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Osobe</h5>
                <br>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Nova osoba
                </button>
            </div>

        </div>
        <div class="ibox-content">
            <datatables
                v-if="show_datatable"
                :url="datatables_url"
                :settings="datatables.settings"
                :title="datatables.title"
                @rowClick="rowClick"
            ></datatables>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import Datatables from "../../../components/datatable/Datatables";
import axios from "axios";
export default {
    name: "Osobe",
    components: { Datatables, Breadcrumbs },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL + 'osoba',
            datatables: {},
            breadcrumbs: []
        }
    },
    methods: {
        rowClick( row ){
            this.$router.push( { path: '/osobe/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/osobe/create' } )
        },

        setDatatable(){
            let vm = this

            vm.datatables = {
                settings: {
                    table_header: [
                        {
                            'id': 'id',
                            'visible': false
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'osobe',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'osoba',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'oib',
                            'title': 'OIB',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'osoba',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'aktualna_grupa_statusa_id',
                            'title': 'Grupa',
                            'type' : function( row ){
                                return row.osoba_aktualna_grupa_statusa && row.osoba_aktualna_grupa_statusa.grupa_statusa ? row.osoba_aktualna_grupa_statusa.grupa_statusa.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'grupa-statusa', {
                                        params: {
                                            filters: {}
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'osoba',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'aktualno_razdoblje_datum_od',
                            'title': 'Početak razdoblja',
                            'type' : function(row){
                                return row.osoba_aktualno_razdoblje ? row.osoba_aktualno_razdoblje.datum_od : ''
                            },
                            'column_visibility_group': {
                                'id': 'osoba',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'aktualno_razdoblje_datum_do',
                            'title': 'Završetak razdoblja',
                            'type' : function(row){
                                return row.osoba_aktualno_razdoblje ? row.osoba_aktualno_razdoblje.datum_do : ''
                            },
                            'column_visibility_group': {
                                'id': 'osoba',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'mjesto',
                            'title': 'Mjesto',
                            'type' : function( row ){
                                return row.posta ? row.posta.mjesto : ''
                            },
                            'column_visibility_group': {
                                'id': 'osoba',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'postanski_broj',
                            'title': 'Poštanski broj',
                            'type' : function( row ){
                                return row.posta ? row.posta.postanski_broj : ''
                            },
                            'column_visibility_group': {
                                'id': 'osoba',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'adresa',
                            'title': 'Adresa',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'osoba',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'email',
                            'title': 'Email',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'osoba',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'mobitel',
                            'title': 'Mobitel',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'osoba',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'telefon',
                            'title': 'Telefon',
                            'type' : 'text',
                            'column_visibility_group': {
                                'id': 'osoba',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'spol',
                            'title': 'Spol',
                            'type' : function( row ){
                                return row.spol ? ( ['0', '1'].includes( row.spol.toString() ) ? ( row.spol.toString() === '1' ? 'M' : 'Ž' ) : '' ) : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '0',
                                        value: 'Ž'
                                    },
                                    {
                                        key: '1',
                                        value: 'M'
                                    }
                                ]
                            },
                            'column_visibility_group': {
                                'id': 'osoba',
                                'visible' : true
                            }
                        },


                    ],
                    column_visibility_groups:{
                        'osoba' : 'Osobe'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Osobe'
            }

            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/osobe',
                    name: 'Osobe'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }

};
</script>

<style scoped>

</style>
