<template>
    <div>
        <osobe-navigation
            :id="id"
            :breadcrumbs="breadcrumbs"
        >
        </osobe-navigation>


        <div v-show="show_skup" class="ibox ">
            <div class="ibox-title">
                <h5>Osoba: {{ naziv }} </h5>
            </div>
            <div class="ibox-content">
                <osoba-forma
                    :disabled="true"
                    :id="id"
                    :modal="false"
                    :action="'show'"
                    @returnData="setData"
                >
                </osoba-forma>

                <br><br>
                <h2><strong>Grupa statusa: {{ status }}</strong></h2>
                <br><br>
                <h2><strong>Razdoblja:</strong></h2>
                <table class="table">
                    <thead>
                    <tr>
                        <th>Početak razdoblja</th>
                        <th>Završetak razdoblja</th>
                        <th>Potrebno skupiti sati</th>
                        <th>Potrebno skupiti sati regulative</th>
                        <th>Skupljeni sati</th>
                        <th>Skupljeni sati regulative</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="razdoblje in razdoblja" :key="razdoblje.id">
                        <td>{{razdoblje.datum_od}}</td>
                        <td>{{ razdoblje.datum_do }}</td>
                        <td>{{ razdoblje.sati }} - {{ razdoblje.umanjeni_sati }} = <strong>{{  razdoblje.sati - razdoblje.umanjeni_sati }}</strong></td>
                        <td>{{ razdoblje.sati_regulativa }} - {{ razdoblje.umanjeni_sati_regulativa }} = <strong>{{  razdoblje.sati_regulativa - razdoblje.umanjeni_sati_regulativa }}</strong></td>
                        <td>{{ razdoblje.skupljeno_sati }}</td>
                        <td>{{ razdoblje.skupljeno_sati_regulativa }}</td>
                    </tr>
                    </tbody>
                </table>
                <br><br><br><br>
            </div>
        </div>
    </div>
</template>

<script>
import OsobeNavigation from "./OsobeNavigation";
import OsobaForma from "./OsobaForma";
import axios from "axios";
import moment from "moment";
export default {
    name: "OsobaDetails",
    components: { OsobaForma, OsobeNavigation },
    props: ['user'],
    data(){
        return {
            id: this.$route.params.osoba_id,
            breadcrumbs: [],
            naziv: '',
            show_skup: true,

            status: '',
            razdoblja:[]
        }
    },
    methods:{

        async fetchRazdoblja(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'osoba-razdoblje', {
                params: {
                    filters: {
                        osoba_id: vm.id
                    }
                }
            } )

            const stavke = await vm.fetchStavke()

            const dodatne_aktivnosti = await vm.fetchDodatneAktivnosti()

            console.log( stavke, dodatne_aktivnosti )

            for( const razdoblje in response.data.data ){

                const filtrirane_stavke = await stavke.filter(
                        stavka =>
                            moment( stavka.skup.datum_i_vrijeme_od, 'DD.MM.YYYY.' )
                                .isAfter( moment( response.data.data[razdoblje].datum_od, 'DD.MM.YYYY.' ) )
                            &&
                            moment( stavka.skup.datum_i_vrijeme_od, 'DD.MM.YYYY.' )
                                .isBefore( moment( response.data.data[razdoblje].datum_do, 'DD.MM.YYYY.' ) )
                    )



                const filtrirane_dodatne_aktivnosti = await dodatne_aktivnosti.filter(
                    dodatna_aktivnost =>
                        moment( dodatna_aktivnost.datum_od, 'DD.MM.YYYY.' )
                            .isSameOrAfter( moment( response.data.data[razdoblje].datum_od, 'DD.MM.YYYY.' ) )
                        &&
                            moment( dodatna_aktivnost.datum_od, 'DD.MM.YYYY.' )
                                .isSameOrBefore( moment( response.data.data[razdoblje].datum_do, 'DD.MM.YYYY.' ) )
                )

                console.log( filtrirane_stavke, filtrirane_dodatne_aktivnosti)

                vm.razdoblja.push({
                    'datum_od' : response.data.data[razdoblje].datum_od,
                    'datum_do' : response.data.data[razdoblje].datum_do,
                    'sati' : response.data.data[razdoblje].sati,
                    'sati_regulativa': response.data.data[razdoblje].sati_regulativa,
                    'umanjeni_sati' :
                        response.data.data[razdoblje].osobe_razdoblja_umanjeni_sati.length
                            ? response.data.data[razdoblje].osobe_razdoblja_umanjeni_sati
                                .map( orus => orus.sati )
                                .reduce( ( prev, next ) => prev + next)
                            : 0,
                    'umanjeni_sati_regulativa' :
                        response.data.data[razdoblje].osobe_razdoblja_umanjeni_sati.length
                            ? response.data.data[razdoblje].osobe_razdoblja_umanjeni_sati
                                .map( orus => orus.sati_regulativa )
                                .reduce( ( prev, next ) => prev + next )
                            : 0,
                    'skupljeno_sati' :
                        (
                            filtrirane_stavke.length
                                ? filtrirane_stavke.map( stavka => stavka.sati )
                                    .reduce( ( prev, next ) => prev + next)
                                : 0
                        )
                        +
                        (
                            filtrirane_dodatne_aktivnosti.length
                                ? filtrirane_dodatne_aktivnosti.map( dodatna_aktivnost => dodatna_aktivnost.sati )
                                    .reduce( ( prev, next ) => prev + next)
                                : 0
                        ),
                    'skupljeno_sati_regulativa' :
                        (
                            filtrirane_stavke.length
                                ? filtrirane_stavke.map( stavka => stavka.sati_regulativa )
                                    .reduce( ( prev, next ) => prev + next)
                                : 0
                        )
                        +
                        (
                            filtrirane_dodatne_aktivnosti.length
                                ? filtrirane_dodatne_aktivnosti.map( dodatna_aktivnost => dodatna_aktivnost.sati_regulativa )
                                    .reduce( ( prev, next ) => prev + next)
                                : 0
                        ),
                })
            }
        },

        async fetchStavke(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'stavka', {
                params: {
                    filters: {
                        osoba_id: vm.id,
                        page:1,
                        per_page: -1
                    }
                }
            } )

            return response.data.data;
        },

        async fetchDodatneAktivnosti(){
            let vm = this
            const response = await axios.get(process.env.VUE_APP_API_URL + 'osoba-dodatna-aktivnost', {
                params: {
                    filters: {
                        osoba_id: vm.id,
                        page:1,
                        per_page: -1
                    }
                }
            } )

            return response.data.data;
        },

        setBreadcrumbs() {
            this.breadcrumbs = [
                {
                    path: '/osobe',
                    name: 'Osobe'
                },
                {
                    path: ( '/osobe/' + this.id ),
                    name: ( 'Osobe: ' + this.naziv )
                }
            ]
        },

        async setData( data ){
            this.naziv = data.ime + ' ' + data.prezime + ' (' + data.oib + ')'
            this.status = data.osoba_grupe_statusa[0] && data.osoba_grupe_statusa[0].grupa_statusa ? data.osoba_grupe_statusa[0].grupa_statusa.naziv : ''
            this.setBreadcrumbs()
            await this.fetchRazdoblja()
        }
    },

};
</script>

<style scoped>

</style>
