import { render, staticRenderFns } from "./IzvjestajiHKIS.vue?vue&type=template&id=67e52a67&scoped=true&"
import script from "./IzvjestajiHKIS.vue?vue&type=script&lang=js&"
export * from "./IzvjestajiHKIS.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67e52a67",
  null
  
)

export default component.exports