<template>

    <nav class="navbar-default navbar-static-side" role="navigation">
        <div class="sidebar-collapse">
            <ul class="nav metismenu" id="side-menu">
                <li class="nav-header">
                    <div class="dropdown profile-element">
                        <span class="block m-t-xs font-bold" style="color: white"> {{ app_name ? app_name : '' }}</span>
                        <span class="block m-t-xs font-bold"> {{ app_owner ? app_owner : '' }}</span>
                    </div>
                    <div class="logo-element">
                        {{ app_name ? app_name : '' }}
                    </div>
                </li>

                <li @click="change_active_link('skupovi')" :class="{'active': active_link === 'skupovi'}">
                    <router-link to="/skupovi"><i class="fa fa-address-book"></i> <span class="nav-label">Skupovi</span></router-link>
                </li>

                <li @click="change_active_link('osobe')" :class="{'active': active_link === 'osobe'}">
                    <router-link to="/osobe"><i class="fa fa-star"></i> <span class="nav-label">Osobe</span></router-link>
                </li>

                <li @click="change_active_link('administratori')" :class="{'active': active_link === 'administratori'}">
                    <router-link to="/administratori"><i class="fa fa-tags"></i> <span class="nav-label">Korisnici</span></router-link>
                </li>


              <li v-if="app_owner === 'HKIE'" @click="change_active_link('izvjestaji-hkie')" :class="{'active': active_link === 'izvjestaji-hkie'}">
                <router-link to="/izvjestaji-hkie"><i class="fa fa-tags"></i> <span class="nav-label">Izvještaji</span></router-link>
              </li>
              <li v-if="app_owner === 'HKIS'" @click="change_active_link('izvjestaji-hkis')" :class="{'active': active_link === 'izvjestaji-hkis'}">
                  <router-link to="/izvjestaji-hkis"><i class="fa fa-tags"></i> <span class="nav-label">Izvještaji</span></router-link>
              </li>
              <li v-if="app_owner === 'HKIG'" @click="change_active_link('izvjestaji-hkig')" :class="{'active': active_link === 'izvjestaji-hkig'}">
                <router-link to="/izvjestaji-hkig"><i class="fa fa-tags"></i> <span class="nav-label">Izvještaji</span></router-link>
              </li>

                <li :class="{'active': active_link === 'postavke' || sec_level_menu === 'postavke' }">
                    <a @click="open_menu('postavke')" aria-expanded="true"><i class="fa fa-cogs"></i> <span class="nav-label">Postavke</span><span class="fa arrow"></span></a>
                    <ul v-if="sec_level_menu === 'postavke'" :class="{'nav':true, 'nav-second-level':true, 'collapse':true, 'in':sec_level_menu === 'postavke' }">

                        <li @click="change_active_link('postavke/dodatne-aktivnosti', 'postavke' )" :class="{'active': active_link === 'postavke/dodatne-aktivnosti'}">
                            <router-link to="/postavke/dodatne-aktivnosti"><span class="nav-label"></span>Dodatne aktivnosti</router-link>
                        </li>

                        <li @click="change_active_link('postavke/drzave', 'postavke' )" :class="{'active': active_link === 'postavke/drzave'}">
                            <router-link to="/postavke/drzave"><span class="nav-label"></span>Države</router-link>
                        </li>

                        <!--<li @click="change_active_link('postavke/grupe-statusa', 'postavke' )" :class="{'active': active_link === 'postavke/grupe-statusa'}">
                            <router-link to="/postavke/grupe-statusa"><span class="nav-label"></span>Grupe Statusa</router-link>
                        </li>-->

                        <li @click="change_active_link('postavke/podrucja-tema', 'postavke' )" :class="{'active': active_link === 'postavke/podrucja-tema'}">
                            <router-link to="/postavke/podrucja-tema"><span class="nav-label"></span>Područja tema</router-link>
                        </li>

                        <li @click="change_active_link('postavke/porezi', 'postavke' )" :class="{'active': active_link === 'postavke/porezi'}">
                            <router-link to="/postavke/porezi"><span class="nav-label"></span>Porezi</router-link>
                        </li>

                        <li @click="change_active_link('postavke/poste', 'postavke' )" :class="{'active': active_link === 'postavke/poste'}">
                            <router-link to="/postavke/poste"><span class="nav-label"></span>Pošte</router-link>
                        </li>

                        <li @click="change_active_link('postavke/suorganizatori', 'postavke' )" :class="{'active': active_link === 'postavke/suorganizatori'}">
                            <router-link to="/postavke/suorganizatori"><span class="nav-label"></span>Suorganizatori</router-link>
                        </li>

                        <li @click="change_active_link('postavke/umanjeni-sati', 'postavke' )" :class="{'active': active_link === 'postavke/umanjeni-sati'}">
                            <router-link to="/postavke/umanjeni-sati"><span class="nav-label"></span>Umanjeni sati</router-link>
                        </li>

                        <li @click="change_active_link('postavke/vrste-prometa', 'postavke' )" :class="{'active': active_link === 'postavke/vrste-prometa'}">
                            <router-link to="/postavke/vrste-prometa"><span class="nav-label"></span>Vrste prometa</router-link>
                        </li>

                        <li @click="change_active_link('postavke/vrste-skupova', 'postavke' )" :class="{'active': active_link === 'postavke/vrste-skupova'}">
                            <router-link to="/postavke/vrste-skupova"><span class="nav-label"></span>Vrste skupova</router-link>
                        </li>

                        <li @click="change_active_link('postavke/vrste-sudionika', 'postavke' )" :class="{'active': active_link === 'postavke/vrste-sudionika'}">
                            <router-link to="/postavke/vrste-sudionika"><span class="nav-label"></span>Vrste sudionika</router-link>
                        </li>

                        <li @click="change_active_link('postavke/vrste-suorganizatora', 'postavke' )" :class="{'active': active_link === 'postavke/vrste-suorganizatora'}">
                            <router-link to="/postavke/vrste-suorganizatora"><span class="nav-label"></span>Vrste suorganizatora</router-link>
                        </li>

                        <li @click="change_active_link('postavke/vrste-tema', 'postavke' )" :class="{'active': active_link === 'postavke/vrste-tema'}">
                            <router-link to="/postavke/vrste-tema"><span class="nav-label"></span>Vrste tema</router-link>
                        </li>

                        <li @click="change_active_link('postavke/vrste-tema2', 'postavke' )" :class="{'active': active_link === 'postavke/vrste-tema2'}">
                            <router-link to="/postavke/vrste-tema2"><span class="nav-label"></span>Vrste podtema</router-link>
                        </li>

                        <li @click="change_active_link('postavke/zupanije', 'postavke' )" :class="{'active': active_link === 'postavke/zupanije'}">
                            <router-link to="/postavke/zupanije"><span class="nav-label"></span>Županije</router-link>
                        </li>

                    </ul>
                </li>


            </ul>

        </div>
    </nav>



</template>

<script>
    import axios from "axios";

    export default {
        name: "Navigation",
        props:[
        ],
        data(){
            return {
                active_link: 'skupovi',
                active_link_old: 'skupovi',
                sec_level_menu: null,
                app_owner:process.env.VUE_APP_APP_OWNER,
                app_name:process.env.VUE_APP_APP_NAME,
            }
        },
        methods:{
            change_active_link( active_link, sec_level_menu = null ){
                this.sec_level_menu = sec_level_menu
                this.active_link_old = this.active_link;
                this.active_link = active_link
            },
            open_menu( sec_level_menu ){
                if( this.sec_level_menu === sec_level_menu ){
                    this.active_link = this.active_link_old
                    this.sec_level_menu = null
                } else {
                    this.active_link_old = this.active_link;
                    this.active_link = sec_level_menu
                    this.sec_level_menu = sec_level_menu
                }
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>
