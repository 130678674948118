<template>
    <div>
        <skupovi-navigation
            :id="skup_id"
            :breadcrumbs="breadcrumbs"
        >
        </skupovi-navigation>
        <div class="ibox">
            <div class="ibox-title">
                <h5>Stavke</h5><br>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="create"
                >
                    Novi unos
                </button>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="importExcel"
                >
                    Uvoz - Excel
                </button>
                <button
                    type="button"
                    class="btn btn-lg btn-primary"
                    @click.prevent="notImportedExcel"
                >
                    Nepreneseni - Excel
                </button>
            </div>
            <div class="ibox-content">
                <datatables
                    v-if="show_datatable"
                    :url="datatables_url"
                    :settings="datatables.settings"
                    :title="datatables.title"
                    @rowClick="rowClick"
                >
                </datatables>
            </div>
        </div>
    </div>
</template>

<script>
import Datatables from "../../../../components/datatable/Datatables";
import SkupoviNavigation from "../SkupNavigation";
import axios from "axios";
export default {
    name: "SkupStavke",
    components: { SkupoviNavigation, Datatables },
    data(){
        return {
            show_datatable: false,
            datatables_url: process.env.VUE_APP_API_URL +  'stavka',
            datatables: {},
            breadcrumbs: [],
            skup_id: this.$route.params.skup_id
        }
    },
    methods:{
        rowClick( row ){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/stavke/' + row.id } )
        },

        create(){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/stavke/create' } )
        },

        importExcel(){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/stavke/import-excel' } )
        },

        notImportedExcel(){
            this.$router.push( { path: '/skupovi/' + this.skup_id + '/stavke/not-imported-excel' } )
        },

        setDatatable() {
            let vm = this;
            vm.datatables = {
                settings: {
                    table_header:[
                        {
                            'id': 'id',
                            'visible': false,
                        },
                        {
                            'id': 'skup_id',
                            'visible': false,
                            'filter':{
                                'type': 'none',
                                'value': vm.skup_id
                            },
                        },
                        {
                            'id': 'aktualna_grupa_statusa_id',
                            'title': 'Grupa',
                            'type' : function( row ){
                                return row.osoba.osoba_aktualna_grupa_statusa ? ( row.osoba.osoba_aktualna_grupa_statusa ? ( row.osoba.osoba_aktualna_grupa_statusa.grupa_statusa ? row.osoba.osoba_aktualna_grupa_statusa.grupa_statusa.naziv : '' ) : '') : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'grupa-statusa', {
                                        params: {
                                            filters: {}
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'ime',
                            'title': 'Ime',
                            'type' : function( row ){
                                return row.osoba ? row.osoba.ime : ''
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'prezime',
                            'title': 'Prezime',
                            'type' : function( row ){
                                return row.osoba ? row.osoba.prezime : ''
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'datum_rodjenja',
                            'title': 'Datum rođenja',
                            'type' : function( row ){
                                return row.osoba.datum_rodjenja ? row.osoba.datum_rodjenja : ''
                            },
                            searchable: false,
                            'column_visibility_group': {
                                'id': 'datum_rodjenja',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'oib',
                            'title': 'OIB',
                            'type' : function( row ){
                                return row.osoba ? row.osoba.oib : ''
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'spol',
                            'title': 'Spol',
                            'type' : function( row ){
                                return [0, 1].includes( row.osoba.spol ) ? ( row.osoba.spol.toString() === '1' ? 'M' : 'Ž' ) : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': [
                                    {
                                        key: '0',
                                        value: 'Ž'
                                    },
                                    {
                                        key: '1',
                                        value: 'M'
                                    }
                                ]
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'vrsta_sudionika_id',
                            'title': 'Vrsta sudionika',
                            'type' : function( row ){
                                return row.vrsta_sudionika ? row.vrsta_sudionika.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'vrsta-sudionika', {
                                        params: {
                                            filters: {}
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'datum',
                            'title': 'Datum stavke',
                            'type' : function( row ){
                                return row.datum
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            },
                            'filter': {
                                'type': 'date',
                            }
                        },
                        {
                            'id': 'sati',
                            'title': 'Sati',
                            'searchable': false,
                            'type' : function( row ){
                                return row.sati
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'sati_regulativa',
                            'title': 'Sati regulativa',
                            'searchable': false,
                            'type' : function( row ){
                                return row.sati_regulativa
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'tema_id',
                            'title': 'Tema',
                            'type' : function( row ){
                                return row.tema ? row.tema.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'tema', {
                                        params: {
                                            filters: {
                                                skup_id: vm.skup_id
                                            }
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                        {
                            'id': 'tema2_id',
                            'title': 'Podtema',
                            'type' : function( row ){
                                return row.tema2 ? row.tema2.naziv : ''
                            },
                            'filter': {
                                'type': 'select',
                                'search': function ( ){
                                    let component = this;

                                    axios.get( process.env.VUE_APP_API_URL +  'tema2', {
                                        params: {
                                            filters: {
                                                skup_id: vm.skup_id
                                            }
                                        }
                                    } )
                                        .then( function ( response ) {
                                            let tmp = [];

                                            response.data.data.forEach( function ( item ) {
                                                tmp.push({
                                                    key: item.id,
                                                    value: item.naziv
                                                } );
                                            } );
                                            component.$data.tmp = tmp;
                                        } )
                                        .catch( function ( error ) {
                                            if(error instanceof Error) {
                                                console.log( 'Error: ' . error);
                                            } else {
                                                console.log( 'Unexpected response: ' . error);
                                            }
                                        } )
                                },
                            },
                            'column_visibility_group': {
                                'id': 'stavke',
                                'visible' : true
                            }
                        },
                    ],
                    column_visibility_groups:{
                        'stavke' : 'Stavke'
                    },
                    order: 'desc',
                    order_by: 'id',
                },
                title: 'Stavke',
            }
            vm.show_datatable = true;
        },

        setBreadcrumbs(){
            this.breadcrumbs = [
                {
                    path: '/skupovi/' + this.skup_id + '/stavke',
                    name: 'Stavke'
                }
            ]
        }
    },
    mounted() {
        this.setDatatable()
        this.setBreadcrumbs()
    }
}
</script>

<style scoped>

</style>
